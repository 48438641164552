import axios from 'axios';

import { setAlert } from './alert';

export const sendMensaje = (mensaje) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(mensaje);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/contacto/enviar_mensaje`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error?.response?.data?.msg) {
            dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}