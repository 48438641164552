import { v4 as uuid } from 'uuid';

import { ALERT } from './types'

export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
    const id = uuid();

    dispatch({
        type: ALERT.AGREGAR,
        payload: {
            msg, alertType, id
        }
    });

    setTimeout(() => {
        dispatch({
            type: ALERT.ELIMINAR,
            payload: {
                id
            }
        });
    }, timeout);
}
